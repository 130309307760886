define("discourse/plugins/discourse-shared-edits/discourse/initializers/extend-composer-service", ["exports", "@ember/service", "discourse/lib/plugin-api"], function (_exports, _service, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SHARED_EDIT_ACTION = "sharedEdit";
  var _default = _exports.default = {
    name: "discourse-shared-edits-composer-service",
    initialize: container => {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.shared_edits_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.6", api => {
        api.modifyClass("service:composer", Superclass => class extends Superclass {
          static #_ = (() => dt7948.g(this.prototype, "sharedEditManager", [_service.service]))();
          #sharedEditManager = (() => (dt7948.i(this, "sharedEditManager"), void 0))();
          async open(opts) {
            await super.open(...arguments);
            if (opts.action === SHARED_EDIT_ACTION) {
              await this.sharedEditManager.subscribe();
            }
          }
          collapse() {
            if (this.model?.action === SHARED_EDIT_ACTION) {
              return this.close();
            }
            return super.collapse(...arguments);
          }
          close() {
            if (this.model?.action === SHARED_EDIT_ACTION) {
              this.sharedEditManager.commit();
            }
            return super.close(...arguments);
          }
          save() {
            if (this.model?.action === SHARED_EDIT_ACTION) {
              return this.close();
            }
            return super.save(...arguments);
          }
          _saveDraft() {
            if (this.model?.action === SHARED_EDIT_ACTION) {
              return;
            }
            return super._saveDraft(...arguments);
          }
        });
      });
    }
  };
});